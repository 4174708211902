(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

require('./modules/loading');

require('./modules/modals');

require('./modules/forms');

require('./modules/masks');

require('./modules/spinner');

require('./modules/scroll');

require('./modules/menus');

require('./modules/tabs');

require('./modules/galleries');

require('./modules/sliders');

require('./modules/animations');

require('./modules/homepage');

require('./modules/production');

require('./modules/contact');

},{"./modules/animations":3,"./modules/contact":4,"./modules/forms":5,"./modules/galleries":6,"./modules/homepage":9,"./modules/loading":10,"./modules/masks":11,"./modules/menus":12,"./modules/modals":13,"./modules/production":14,"./modules/scroll":15,"./modules/sliders":16,"./modules/spinner":17,"./modules/tabs":18}],2:[function(require,module,exports){
module.exports={
  "mobile": {
    "landscape": 812,
    "portrait": 700,
    "small": 480
  },
  "tablet": {
    "landscape": 1024,
    "portrait": 768
  },
  "notebook": {
    "small": 1280,
    "normal": 1466
  }
}

},{}],3:[function(require,module,exports){
'use strict';

$(window).on('resize initVariables', function () {
  $('.js-set-css-height').each(function () {
    var $this = $(this);
    $this[0].style.maxHeight = 'none';
    var height = $this.height();
    $this[0].style.maxHeight = '';
    $this[0].style.setProperty('--max-height', height + 'px');
  });
}).trigger('initVariables');

},{}],4:[function(require,module,exports){
'use strict';

$(document).on('change', '.js-contact-country-selector', function () {
  $('#' + $(this).val()).css('display', 'flex').hide().stop(true, true).fadeIn().siblings().stop(true, true).hide();
});

$('.js-toggle-country').click(function (e) {
  e.preventDefault();
  $('.js-contact-country-selector').val($(this).data('country')).trigger('change').selectric('refresh');
  $(window).scrollTo('#distributors', 200);
});

$('.js-toggle-countries-list').click(function () {
  $(this).hide().closest('.contact-agencies__list').toggleClass('is-expanded');
});

$('.js-toggle-distributor').click(function (e) {
  e.preventDefault();
  var $this = $(this);
  $this.addClass('is-active').siblings().removeClass('is-active');
  $($this.attr('href')).fadeIn().siblings('.item').stop(true, true).hide();
});

},{}],5:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

// Selectric
// ---------

_globals.$DOCUMENT.on('initSelectric yiiListViewUpdated', function () {
  $('select').selectric({
    disableOnMobile: false,
    nativeOnMobile: true
  });
}).trigger('initSelectric');

// Checkboxes
// ----------

_globals.$BODY.on('change initCheckboxes', '.js-checkbox input', function (event) {
  var $inp = $(event.target);
  var $label = $inp.closest('.js-checkbox');

  if ($inp.prop('checked')) {
    $label.addClass('is-checked');
  } else {
    $label.removeClass('is-checked');
  }
});
$('.js-checkbox input').trigger('initCheckboxes');

// Radio buttons
// -------------

_globals.$BODY.on('change initRadio', '.js-radio input', function (event) {
  var $inp = $(event.target);
  var $group = $('[name="' + $inp.attr('name') + '"]');
  var $labels = $group.closest('.js-radio');
  var $selected_item = $labels.find('input').filter(':checked').closest('.js-radio');

  $labels.removeClass('is-checked');
  $selected_item.addClass('is-checked');
});
$('.js-radio input').trigger('initRadio');

// Textarea autosize
// -----------------

$(function () {
  autosize($('textarea'));
});

// Fileinputs
// ----------

_globals.$BODY.on('change', '.js-fileinput input', function (event) {
  console.log(event);
  $(event.target).closest('.js-fileinput').parent().find('.js-fileinput-filename').text($(this).val());
});

},{"./globals":7}],6:[function(require,module,exports){
'use strict';

var _helpers = require('./helpers');

$.fancybox.defaults.hash = false;

if ((0, _helpers.IS_SMALL_MOBILE_WIDTH)()) {
  $().fancybox({
    selector: '.js-mobile-zoom'
  });
} else {
  $('.js-mobile-zoom').click(function (e) {
    e.preventDefault();
  });
}

},{"./helpers":8}],7:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TOSRUS_DEFAULTS = exports.$BODY = exports.$HTML = exports.$DOCUMENT = exports.$WINDOW = exports.HEADER_HEIGHT = exports.NOTEBOOK_WIDTH = exports.SMALL_NOTEBOOK_WIDTH = exports.TABLET_WIDTH = exports.PORTRAIT_TABLET_WIDTH = exports.LANDSCAPE_MOBILE_WIDTH = exports.MOBILE_WIDTH = exports.SMALL_MOBILE_WIDTH = exports.IS_TOUCH_DEVICE = exports.IS_IE = exports.IS_IOS = exports.IS_DESKTOP = exports.IS_MOBILE = undefined;

var _mediaQueriesConfig = require('../media-queries-config.json');

var _mediaQueriesConfig2 = _interopRequireDefault(_mediaQueriesConfig);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// Const
// -----

var IS_MOBILE = exports.IS_MOBILE = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i) !== null;
var IS_DESKTOP = exports.IS_DESKTOP = !IS_MOBILE;
var IS_IOS = exports.IS_IOS = navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? true : false;
var IS_IE = exports.IS_IE = navigator.appVersion.indexOf("MSIE") !== -1 || navigator.userAgent.match(/Trident.*rv[ :]*11\./);
var IS_TOUCH_DEVICE = exports.IS_TOUCH_DEVICE = 'ontouchstart' in document;

var HTML = document.documentElement;

HTML.classList.remove('no-js');

if (IS_MOBILE) HTML.classList.add('is-mobile');
if (IS_DESKTOP) HTML.classList.add('is-desktop');
if (IS_IOS) HTML.classList.add('is-ios');
if (IS_IE) HTML.classList.add('is-ie');
if (IS_TOUCH_DEVICE) HTML.classList.add('is-touch-device');

var SMALL_MOBILE_WIDTH = exports.SMALL_MOBILE_WIDTH = _mediaQueriesConfig2.default.mobile.small;
var MOBILE_WIDTH = exports.MOBILE_WIDTH = _mediaQueriesConfig2.default.mobile.portrait;
var LANDSCAPE_MOBILE_WIDTH = exports.LANDSCAPE_MOBILE_WIDTH = _mediaQueriesConfig2.default.mobile.landscape;
var PORTRAIT_TABLET_WIDTH = exports.PORTRAIT_TABLET_WIDTH = _mediaQueriesConfig2.default.tablet.portrait;
var TABLET_WIDTH = exports.TABLET_WIDTH = _mediaQueriesConfig2.default.tablet.landscape;
var SMALL_NOTEBOOK_WIDTH = exports.SMALL_NOTEBOOK_WIDTH = _mediaQueriesConfig2.default.notebook.small;
var NOTEBOOK_WIDTH = exports.NOTEBOOK_WIDTH = _mediaQueriesConfig2.default.notebook.normal;

var HEADER_HEIGHT = exports.HEADER_HEIGHT = $('.header').height();

// Selectors
// ---------

var $WINDOW = exports.$WINDOW = $(window);
var $DOCUMENT = exports.$DOCUMENT = $(document);
var $HTML = exports.$HTML = $(document.documentElement);
var $BODY = exports.$BODY = $(document.body);

// Tosrus default settings
// -----------------------

var TOSRUS_DEFAULTS = exports.TOSRUS_DEFAULTS = {
  buttons: {
    next: true,
    prev: true
  },
  keys: {
    prev: 37,
    next: 39,
    close: 27
  },
  wrapper: {
    onClick: 'close'
  }
};

// IE detection
// ------------

if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
  $('body').addClass('internet-explorer');
}

},{"../media-queries-config.json":2}],8:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IS_SMALL_MOBILE_WIDTH = exports.IS_LANDSCAPE_MOBILE_WIDTH = exports.IS_MOBILE_WIDTH = exports.IS_TABLET_WIDTH = exports.IS_SMALL_NOTEBOOK_WIDTH = exports.IS_NOTEBOOK_WIDTH = exports.IS_DESKTOP_WIDTH = exports.WINDOW_HEIGHT = exports.WINDOW_WIDTH = undefined;
exports.status = status;

var _globals = require('./globals');

var WINDOW_WIDTH = exports.WINDOW_WIDTH = window.innerWidth || _globals.$WINDOW.width();
var WINDOW_HEIGHT = exports.WINDOW_HEIGHT = _globals.$WINDOW.height();
_globals.$WINDOW.resize(function () {
  exports.WINDOW_WIDTH = WINDOW_WIDTH = window.innerWidth || _globals.$WINDOW.width();
  exports.WINDOW_HEIGHT = WINDOW_HEIGHT = _globals.$WINDOW.height();
});

var IS_DESKTOP_WIDTH = exports.IS_DESKTOP_WIDTH = function IS_DESKTOP_WIDTH() {
  return WINDOW_WIDTH > _globals.NOTEBOOK_WIDTH;
};
var IS_NOTEBOOK_WIDTH = exports.IS_NOTEBOOK_WIDTH = function IS_NOTEBOOK_WIDTH() {
  return WINDOW_WIDTH > _globals.SMALL_NOTEBOOK_WIDTH && WINDOW_WIDTH <= _globals.NOTEBOOK_WIDTH;
};
var IS_SMALL_NOTEBOOK_WIDTH = exports.IS_SMALL_NOTEBOOK_WIDTH = function IS_SMALL_NOTEBOOK_WIDTH() {
  return WINDOW_WIDTH > _globals.TABLET_WIDTH && WINDOW_WIDTH <= _globals.SMALL_NOTEBOOK_WIDTH;
};
var IS_TABLET_WIDTH = exports.IS_TABLET_WIDTH = function IS_TABLET_WIDTH() {
  return WINDOW_WIDTH >= _globals.PORTRAIT_TABLET_WIDTH && WINDOW_WIDTH <= _globals.TABLET_WIDTH;
};
var IS_MOBILE_WIDTH = exports.IS_MOBILE_WIDTH = function IS_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.MOBILE_WIDTH;
};
var IS_LANDSCAPE_MOBILE_WIDTH = exports.IS_LANDSCAPE_MOBILE_WIDTH = function IS_LANDSCAPE_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.LANDSCAPE_MOBILE_WIDTH;
};
var IS_SMALL_MOBILE_WIDTH = exports.IS_SMALL_MOBILE_WIDTH = function IS_SMALL_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.SMALL_MOBILE_WIDTH;
};

// Response status
function status(response) {
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response;
}

},{"./globals":7}],9:[function(require,module,exports){
'use strict';

$('.js-homepage-powers-toggler').change(function () {
  $(this).closest('.slide').addClass('activated').toggleClass('details-view');
});

$('.homepage-powers__list .item').on('mouseenter', function () {
  var _this = this;

  if ($(window).width() <= 480) {
    setTimeout(function () {
      $(window).scrollTo($(_this), { duration: 200, offset: -$('.header').height() - 15 });
    }, 750);
  }
});

},{}],10:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

var _helpers = require('./helpers');

$(document).ready(function () {
  // Homepage loading animations etc

  var $homepage = $('.homepage');
  var FADE_DURATION = 1000;
  var LOADING_LIMIT = 7000;
  var promisesCollection = [];
  var $pageLoadingOverlay = $('.js-page-loading-overlay');
  var loadingTimeout = void 0;
  var $progressbar = $('.js-page-loading-progress');
  var counter = $('.header .js-wait-for-background, .homepage-intro .js-wait-for-background, .js-intro-bg-slider').length;
  var progress = 0;

  if ($homepage.length) {
    // if (IS_SMALL_MOBILE_WIDTH()) {
    //   $('.intro-video-screen--video').addClass('js-wait-for-background');
    // }

    _globals.$WINDOW.on('resize.page-loading-overlay init-page-loading-overlay', function () {
      $pageLoadingOverlay.height(_helpers.WINDOW_HEIGHT);
      _globals.$BODY.css('height', _helpers.WINDOW_HEIGHT);
    }).trigger('init-page-loading-overlay');

    // $('.header, .intro-video-screen').each(function() {
    $('.header, .homepage-intro, .monaco-intro').each(function () {
      var $this = $(this);
      var promise = new Promise(function (resolve, reject) {
        $this.imagesLoaded({ background: '.js-wait-for-background' }).always(function () {
          return resolve(true);
        }).progress(function () {
          progress += 1;
          $progressbar.css('width', progress / counter * 100 + '%');
        });
      });

      promisesCollection.push(promise);
    });

    // const promise = new Promise((resolve, reject) => {
    //   $('.js-intro-bg-slider').on('sliderInitialized', () => resolve(true));
    // });

    // promisesCollection.push(promise);

    // if (!IS_SMALL_MOBILE_WIDTH()) {
    //   const promise = new Promise((resolve, reject) => {
    //     $('.intro-video-screen video').on('loadeddata', () => resolve(true));
    //   });

    //   promisesCollection.push(promise);
    // }

    setTimeout(function () {
      Promise.all(promisesCollection).then(function () {
        return onPageLoad();
      }).catch(function () {});
    }, 1100);

    loadingTimeout = setTimeout(function () {
      return onPageLoad();
    }, LOADING_LIMIT);
  } else {
    onPageLoad();
  }

  function onPageLoad() {
    if (loadingTimeout) clearTimeout(loadingTimeout);
    $(document).trigger('page-is-ready');
    _globals.$BODY.addClass('page-is-ready').removeAttr('style');
    $homepage.find('.js-animation').addClass('is-animated');
    setTimeout(function () {
      $('.js-intro-caption-part').addClass('is-animated');
    }, 2000);
    _globals.$WINDOW.off('resize.page-loading-overlay');
    $pageLoadingOverlay.fadeOut(FADE_DURATION);

    if ($homepage.length) {
      var hash = document.location.hash;
      if (hash) {
        $(window).scrollTo(hash);
      }
    }
  }
});

},{"./globals":7,"./helpers":8}],11:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

(function () {

  $('input[type="tel"]').mask('+7 (999) 999-99-99', {
    autoclear: false
  });

  if (_globals.IS_DESKTOP) {
    $('input[type="date"]').attr('type', 'text');

    // Date
    $('.js-date-mask').mask('99/99/9999', {
      placeholder: 'дд.мм.гггг',
      autoclear: false
    });

    // Time
    $('.js-time-mask').mask('99:99', {
      placeholder: 'чч:мм',
      autoclear: false
    });
  }
})();

},{"./globals":7}],12:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

var $menus = $('.js-menu');

if (_globals.IS_DESKTOP) {
  $menus.on('mouseenter.js-menu', 'li', function () {
    var $this = $(this);

    clearTimeout($this.data('unhoverTimeout'));

    if ($this.hasClass('has-dropdown')) {
      $this.data('hoverTimeout', setTimeout(function () {
        $this.addClass('is-hovered');
      }, 500));
    } else {
      $this.addClass('is-hovered');
    }
  });

  $menus.on('mouseleave.js-menu', 'li', function () {
    var $this = $(this);

    clearTimeout($this.data('hoverTimeout'));

    $this.data('unhoverTimeout', setTimeout(function () {
      $this.removeClass('is-hovered');
    }, 200));
  });
}

if (_globals.IS_MOBILE) {
  $menus.on('click.js-m-menu', 'a', function (e) {
    e.preventDefault();

    var $anchor = $(this);
    var $parent = $anchor.parent();
    var has_dropdown = $parent.hasClass('has-dropdown');
    var is_hovered = $parent.hasClass('is-hovered');
    var link = $anchor.attr('href');

    $parent.siblings().removeClass('is-hovered');

    if (!has_dropdown) {
      location.href = $anchor.attr('href');
    } else {
      if (is_hovered && link) {
        location.href = link;
      } else {
        $parent.toggleClass('is-hovered');
      }
    }
  });
}

$('.js-toggle-mobile-menu').click(function (e) {
  e.preventDefault();

  var $this = $(this);
  var opened_class = 'menu-is-opened';

  _globals.$BODY.toggleClass(opened_class);
  $this.toggleClass('is-active');

  if (_globals.$BODY.hasClass(opened_class)) {
    _globals.$BODY.on('click.close-menu', function (e) {
      var $target = $(e.target);

      if ($target.closest('.mobile-menu').length || $target.hasClass('js-toggle-mobile-menu')) {
        e.stopPropagation();
        return false;
      }

      _globals.$BODY.removeClass(opened_class).off('click.close-menu');
      $this.removeClass('is-active');
    });
  } else {
    _globals.$BODY.removeClass(opened_class).off('click.close-menu');
    $this.removeClass('is-active');
  }
});

$('.js-toggle-menu-item').click(function (e) {
  e.preventDefault();
  e.stopPropagation();

  $(this).closest('.has-dropdown').toggleClass('is-hovered');
});

$('.js-mobile-search .btn').click(function () {
  $(this).closest('form').submit();
});

$('.js-toggle-footer-menu').click(function (e) {
  $(this).closest('.footer__column').toggleClass('is-expanded');
});

},{"./globals":7}],13:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

// Open popup
_globals.$DOCUMENT.on('click.overlay-open', '.js-overlay', function (e) {
  e.preventDefault();

  var popup = $(this).attr('href');

  $.overlayLoader(true, {
    node: popup,
    hideSelector: '.js-close-popup',
    fixedElements: '.js-fixed-element',
    onShow: function onShow() {
      if (history.pushState) {
        window.history.pushState(null, null, document.location.pathname + popup);
      }
    },
    onHide: function onHide() {
      if (history.replaceState) {
        window.history.replaceState(null, null, document.location.pathname);
      }
    }
  });
});

// Autofocus
_globals.$DOCUMENT.on('overlayLoaderShow', function (e, $node) {
  $node.find('.js-autofocus-inp').focus();
});

// Popup initializaation by url-hashes
$(function () {
  $(window).on('hashchange initialLoading', function () {
    setTimeout(function () {
      initPopupByHash();
    }, 100);
  }).trigger('initialLoading');
});

function initPopupByHash() {
  var hash = document.location.hash.replace('!', '');
  var $element = $(hash);

  if ($element.length && $element.hasClass('popup') && !$element.closest('#uniloader-overlay').length) {
    var $link = $('a[href="' + hash + '"]').first();
    _globals.$WINDOW.scrollTo($link.closest('.screen'), { offset: -_globals.HEADER_HEIGHT });
    $link.click();
  }
}

},{"./globals":7}],14:[function(require,module,exports){
'use strict';

var _helpers = require('./helpers');

$('.js-hover-serie').on('mouseenter', function () {
  $(this).addClass('is-active').siblings().removeClass('is-active');
});

// if (IS_LANDSCAPE_MOBILE_WIDTH()) {
//   $('.js-hover-serie:first').addClass('is-active');
// }

},{"./helpers":8}],15:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

var _helpers = require('./helpers');

// Scroll to
// ---------

_globals.$DOCUMENT.on('click.scroll-to', '.js-scroll-to', function (e) {
  e.preventDefault();

  var $lnk = $(this);
  var $elem_to_scroll = $($lnk.attr('href'));
  var speed = $lnk.data('speed') || 150;
  var offset = $lnk.data('offset') || 0;

  _globals.$WINDOW.scrollTo($elem_to_scroll, { duration: speed, offset: offset });
});

// Scrolling to top
// ----------------

if (!(0, _helpers.IS_MOBILE_WIDTH)()) {
  var $go_top_btn = $('<div class="go-top-btn"></div>');

  $go_top_btn.click(function () {
    _globals.$WINDOW.scrollTo(0, 200);
  });

  _globals.$WINDOW.scroll(function () {
    var scroll_top = _globals.$WINDOW.scrollTop();
    if (scroll_top > 0) {
      $go_top_btn.addClass('is-visible');
    } else {
      $go_top_btn.removeClass('is-visible');
    }
  });

  _globals.$BODY.append($go_top_btn);
}

// Inview
// ------

$('.js-inview').each(function (index) {
  var _this = this;

  $(this).bind('inview.trigger', function (event, isInView, visiblePartX, visiblePartY) {
    // if (!IS_SMALL_MOBILE_WIDTH() && !$(this).hasClass('js-inview-autoheight') ? visiblePartY == 'center' : isInView) {
    if (isInView) {
      $(_this).addClass('inview').unbind('inview.trigger');
    } else {
      $(_this).removeClass('inview');
    }
    // $(window).trigger('inviewTriggered');
  });
});

$('.js-inview-watch').each(function (index) {
  var _this2 = this;

  $(this).bind('inview.watcher', function (event, isInView, visiblePartX, visiblePartY) {
    if (visiblePartY == 'center') {
      $(_this2).addClass('inview-now');
    } else {
      $(_this2).removeClass('inview-now');
    }
    // $(window).trigger('inviewTriggered');
  });
});

$('.js-inview-watch-full').each(function (index) {
  var _this3 = this;

  $(this).bind('inview.watcher', function (event, isInView, visiblePartX, visiblePartY, inTheMiddle) {
    var $this = $(_this3);
    if ($this.data('timeout')) {
      clearTimeout($this.data('timeout'));
    }
    if (inTheMiddle) {
      $this.addClass('inview-now');
    } else {
      $this.data('timeout', setTimeout(function () {
        $this.removeClass('inview-now');
      }, 300));
    }
    // $(window).trigger('inviewTriggered');
  });
});

// $(window).on('inviewTriggered.progress', () => {
//   const $sections = $('.js-inview');
//   const $activeSection = $('.js-inview.inview:last');
//   const link = '#' + $activeSection.attr('id');
//   $('.header__menu li.is-active').removeClass('is-active');
//   $('.header__menu').find(`a[href="${link}"]`).closest('li').addClass('is-active');
// });


var scrollPos = $(window).scrollTop();
var wait = false;

_globals.$WINDOW.scroll(function () {
  if (!wait) {
    checkScrollPos();
    wait = true;
    setTimeout(function () {
      wait = false;
    }, 150);
  }
});

function checkScrollPos() {
  var newScrollPos = _globals.$WINDOW.scrollTop();
  if (newScrollPos > 10 && newScrollPos > scrollPos) {
    _globals.$BODY.addClass('scrolledDown').removeClass('scrolledUp');
  } else {
    _globals.$BODY.addClass('scrolledUp').removeClass('scrolledDown');
  }
  scrollPos = newScrollPos;
}

},{"./globals":7,"./helpers":8}],16:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

var _helpers = require('./helpers');

$('.js-slideshow').each(function () {
  var $this = $(this);

  $this.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    arrows: true,
    pauseOnHover: false
  });
});

$('.js-personnel-slider').each(function () {
  var $this = $(this);

  $this.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    dots: true,
    arrows: false
  });
});

$(document).on('page-is-ready', function () {
  var introTimeout = (0, _helpers.IS_LANDSCAPE_MOBILE_WIDTH)() ? 0 : 2000;
  setTimeout(function () {
    $('.js-intro-bg-slider').each(function () {
      var $this = $(this);
      var $parent = $this.parent();

      // $this.on('afterChange', function(event, slick, currentSlide) {
      //   const offset = $(`.homepage-intro__dots li:eq(${currentSlide})`).offset().top + 'px';
      //   $('.homepage-intro .slide__overlay .line:eq(2)')[0].style.setProperty('--offset', offset);
      // })

      $this.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        if (nextSlide == 0) {
          $this.closest('.slide').addClass('light-text');
        } else {
          $this.closest('.slide').removeClass('light-text');
        }
      });

      $this.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        arrows: false,
        dots: true,
        appendDots: $parent.find('.homepage-intro__dots'),
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        fade: true,
        customPaging: function customPaging(slider, i) {
          var caption = $(slider.$slides[i]).find('.item').data('caption');
          return '\n            <button class="homepage-intro__dots-item" data-text="' + caption + '">\n              ' + caption + '\n            </button>\n          ';
        }
      });
    });
  }, introTimeout);
});

$('.js-mobile-actions-slider').each(function () {
  var $this = $(this);

  $this.slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    responsive: [{
      breakpoint: _globals.PORTRAIT_TABLET_WIDTH + 1,
      settings: {
        slidesToShow: 1
      }
    }]
  });
});

setTimeout(function () {
  $('.js-manufacturing-slider').each(function () {
    var $this = $(this);

    $this.on('init', function (event, slick, currentSlide) {
      $('.manufacturing-rotator .slick-dots li:first').addClass('is-active');

      // Slides handling by url-hashes
      $(window).on('hashchange initialLoading', function () {
        setTimeout(function () {
          goToSlideByHash();
        }, 350);
      }).trigger('initialLoading');

      $(window).scroll(function () {
        if ($(window).scrollTop() - 200 > $('.manufacturing-rotator').offset().top) {
          $('.manufacturing-rotator .slick-dots .is-active').removeClass('is-active');
        }
      });
    });

    function goToSlideByHash() {
      var hash = document.location.hash.replace('!', '');
      var $element = $(hash);
      if ($element.length && $element.closest('.manufacturing-rotator').length) {
        var index = $element.closest('.slick-slide').index();
        $('.js-manufacturing-slider').slick('slickGoTo', index);
        // $(`.manufacturing-rotator .slick-dots li:nth-child(${index})`).click();
      }
    }

    $this.on('afterChange', function (event, slick, currentSlide) {
      if ((0, _helpers.IS_SMALL_MOBILE_WIDTH)()) return;
      $(window).scrollTo($('.manufacturing-rotator'), {
        duration: 200,
        offset: -$('.header').height() + 20,
        complete: function complete() {
          $('.manufacturing-rotator .slick-dots .slick-active').addClass('is-active').siblings().removeClass('is-active');
          if (history.pushState) {
            window.history.pushState(null, null, document.location.pathname + '#!' + $(slick.$slides[currentSlide]).find('.manufacturing-rotator-slide').attr('id'));
          }
        }
      });
      var $slide = $(slick.$slides[currentSlide]);
      if ($slide.find('.js-cerificates-slider').length) {
        $('.js-cerificates-slider')[0].slick.refresh();
      }
    });

    $this.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      arrows: false,
      dots: true,
      adaptiveHeight: true,
      swipe: false,
      // fade: true,
      customPaging: function customPaging(slider, i) {
        var $slide = $(slider.$slides[i]).find('.item');
        var caption = $slide.data('caption');
        var pic = $slide.data('image');
        return '\n          <button class="manufacturing-slider-dot">\n            ' + caption + '\n            <img class="manufacturing-slider-dot__image" src="' + pic + '" alt="">\n          </button>\n        ';
      },

      responsive: [{
        breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
        settings: 'unslick'
      }]
    });
  });

  $('.js-toggle-rotator-slide').click(function () {
    var $this = $(this);
    var $slide = $this.closest('.manufacturing-rotator-slide');

    $slide.toggleClass('is-expanded');

    if ($slide.find('.js-manufacturing-gallery-slider').length) {
      $('.js-manufacturing-gallery-slider')[0].slick.refresh();
    }

    if ($slide.find('.js-cerificates-slider').length) {
      $('.js-cerificates-slider')[0].slick.refresh();
    }
  });
}, 0);

$(function () {
  $('.js-manufacturing-products-slider').each(function () {
    var $this = $(this);

    $this.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      dots: false,
      speed: 1000,
      infinite: false,
      responsive: [{
        breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
        settings: {
          dots: true
        }
      }]
    });
  });
});

$('.js-manufacturing-gallery-slider').each(function () {
  var $this = $(this);

  $this.on('afterChange', function (event, slick, currentSlide) {
    event.stopPropagation();
  });

  $this.slick({
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: true,
    dots: false,
    speed: 1000,
    responsive: [{
      breakpoint: _globals.PORTRAIT_TABLET_WIDTH + 1,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }, {
      breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true
      }
    }]
  });
});

$('.js-cerificates-slider').each(function () {
  var $this = $(this);

  $this.on('afterChange', function (event, slick, currentSlide) {
    event.stopPropagation();
  });

  $this.slick({
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    speed: 1000,
    responsive: [{
      breakpoint: _globals.PORTRAIT_TABLET_WIDTH + 1,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    }, {
      breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true
      }
    }]
  });
});

$(function () {
  if (_helpers.IS_SMALL_MOBILE_WIDTH) {
    // Slides handling by url-hashes
    $(window).on('hashchange mobileInitialLoading', function () {
      setTimeout(function () {
        goToSlideByHashForMobile();
      }, 100);
    }).trigger('mobileInitialLoading');
  }

  function goToSlideByHashForMobile() {
    var hash = document.location.hash.replace('!', '');
    var $element = $(hash);
    if ($element.length && $element.closest('.manufacturing-rotator').length) {
      var $link = $element.closest('.manufacturing-rotator-slide').find('.js-toggle-rotator-slide');
      $link.click();
      setTimeout(function () {
        $(window).scrollTo($link, { duration: 200, offset: -$('.header').height() });
      }, 350);
    }
  }

  $('.js-hash-scroll-fix').click(function () {
    var hash = document.location.hash.replace('!', '').replace('#', '');
    if ($(this).attr('href').replace('!', '').split('#')[1] == hash) {
      $(window).scrollTo($('#' + hash), { duration: 200, offset: -$('.header').height() });
    }
  });
});

},{"./globals":7,"./helpers":8}],17:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

_globals.$DOCUMENT.on('mousedown.js-spinner', '.js-spinner-down, .js-spinner-up', function () {
  var $spinner_control = $(this);
  var $input = $spinner_control.siblings('.inp');
  var step = $input.data('step') ? $input.data('step') : 1;
  var may_be_zero = $input.data('zero') ? $input.data('zero') : false;
  var value = parseInt($input.val(), 10);
  var inc_timeout = void 0,
      inc_interval = void 0,
      dec_timeout = void 0,
      dec_interval = void 0;

  $spinner_control.on('mouseup.js-spinner', clearAll).on('mouseleave.js-spinner', $spinner_control, clearAll);

  if ($spinner_control.hasClass('js-spinner-down')) {
    decVal();dec_timeout = setTimeout(function () {
      dec_interval = setInterval(decVal, 70);
    }, 300);
  }

  if ($spinner_control.hasClass('js-spinner-up')) {
    incVal();inc_timeout = setTimeout(function () {
      inc_interval = setInterval(incVal, 70);
    }, 300);
  }

  function incVal() {
    if ($.isMouseLoaderActive()) return;

    value = value + step;
    $input.val(value).change();
  }

  function decVal() {
    if ($.isMouseLoaderActive()) return;

    if (may_be_zero) {
      if (value >= step) {
        value = value - step;
        $input.val(value).change();
      }
    } else {
      if (value > step) {
        value = value - step;
        $input.val(value).change();
      }
    }
  }

  function clearAll() {
    clearTimeout(dec_timeout);clearInterval(dec_interval);
    clearTimeout(inc_timeout);clearInterval(inc_interval);
  }
});

_globals.$DOCUMENT.on('keydown', '.js-spinner .inp', function (e) {
  var $input = $(this);

  if (e.keyCode == 46 || e.keyCode == 8 || e.keyCode == 9 || e.keyCode == 27 || e.keyCode == 65 && e.ctrlKey === true || e.keyCode >= 35 && e.keyCode <= 39) {
    return;
  } else {
    if ((e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
  }
});

_globals.$DOCUMENT.on('keyup paste', '.js-spinner .inp', function (e) {
  var $input = $(this);
  var may_be_zero = $input.data('zero') ? $input.data('zero') : false;

  if ($input.val() == 0 || $input.val() == '') {
    if (!may_be_zero) {
      $input.val(1);
    } else {
      $input.val(0);
    }
  }
});

},{"./globals":7}],18:[function(require,module,exports){
'use strict';

$('.js-tabs .tabs-nav li a').click(function (e) {
  e.preventDefault();

  var $this = $(this);
  var $panel = $($this.attr('href'));

  $this.closest('li').addClass('is-active').siblings().removeClass('is-active');
  $panel.closest('.tabs').find('.tabs-panel').removeClass('is-active').hide();
  $panel.fadeIn().addClass('is-active');
});

$(function () {
  $('.js-tabs').each(function () {
    $(this).find('.tabs-nav li:first a').click();
  });
});

$('.js-move-to-front').click(function () {
  $(this).addClass('is-active').siblings().removeClass('is-active');
});

$('.js-toggle-question').click(function () {
  $(this).closest('.item').toggleClass('is-expanded');
});

$('.js-toggle-all-questions').click(function () {
  $(this).closest('.js-questions-list').addClass('is-expanded');
});

},{}]},{},[1]);
